import { isMobile } from "react-device-detect";
import { copyToClipboard } from "./utils";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { API_URL, DOWNLOAD_URL,FB_PIXEL_ACCESS_TOKENS } from "./urls";

export function useDownload() {
  const [searchParams] = useSearchParams();

  const adjustId = "1i1vztqh";
  const campaign = searchParams.get("campaign");
  const adgroup = searchParams.get("adgroup");
  const creative = searchParams.get("creative");
  const fbclid = searchParams.get("fbclid");
  const fb_pixel_id = searchParams.get("fb_dynamic_pixel") || "1799564210786801";
  const fb_access_token = FB_PIXEL_ACCESS_TOKENS[fb_pixel_id as keyof typeof FB_PIXEL_ACCESS_TOKENS];

  const onDownload = async () => {
    const inviteCode = searchParams.get("invite");

    if (inviteCode) {
      copyToClipboard(inviteCode as string);
    }

    if (!isMobile) {
      return "popup";
    } else {
      // window.open(DOWNLOAD_URL, "_blank");
      // return;
      let newWindow: Window | null = null;
      newWindow = window;
      // const apiUrl = API_URL;
      // let downloadUrl = null;
      // try {
      //   const response = await axios.get(apiUrl);
      //   downloadUrl = response.data.link_jump;
      // } catch (error) {
      //   console.error(error);
      // }
      // if (!downloadUrl) {
      //   if (newWindow) {
      //     newWindow.close();
      //   }
      //   return;
      // }
      const url: string = `${window.location.origin}/download`;
      const encodedUrl = encodeURIComponent(DOWNLOAD_URL);
      console.log(encodedUrl);
      // const encodedUrl = encodeURIComponent(DOWNLOAD_URL);
      if (fbclid) {
        const params = {
          campaign,
          adgroup,
          creative,
          fbclid,
          fb_pixel_id
        };
        const queryString = Object.keys(params)
          .filter(
            (key) =>
              params[key as keyof typeof params] !== null &&
              params[key as keyof typeof params] !== undefined
          )
          .map(
            (key) =>
              `${key}=${encodeURIComponent(
                params[key as keyof typeof params] as string
              )}`
          )
          .join("&");

        // Modify the URL of the newly opened window
        if (newWindow) {
          newWindow.location.href = `https://app.adjust.com/${adjustId}?${queryString}&fb_access_token=${fb_access_token}&redirect=${encodedUrl}`;
        }
      } else {
        if (newWindow) {
          newWindow.location.href = DOWNLOAD_URL;
        }
      }
    }
  };

  return { onDownload };
}
