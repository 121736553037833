import React from "react";
import { useTheme } from "next-themes";
import { useTranslation } from "react-i18next";
import highValue from "./../assets/high-value.png";
import instant from "./../assets/instant.png";
import play from "./../assets/play.png";
import quick from "./../assets/quick.png";
import dc from "./../assets/dc.png";
import legal from "./../assets/legal.png";
import MagicCard from "./magicui/magic-card";
import BlurFade from "./magicui/blur-fade";
import { enableAnimations } from "../lib/utils";

const list = [
  {
    titleKey: "playCashGames",
    subKey: "termsApply",
    asset: highValue
  },
  {
    titleKey: "withdrawWinningsInstantly",
    subKey: "withinMinutes",
    asset: instant
  },
  {
    titleKey: "depositAnytime",
    subKey: "whereverYouAre",
    asset: dc
  },
  {
    titleKey: "quickMatchmaking",
    subKey: "zeroWaitTime",
    asset: quick
  },
  {
    titleKey: "games25Plus",
    subKey: "unlimitedPractices",
    asset: play
  },
  {
    titleKey: "noComplications",
    subKey: "transparentProcess",
    asset: legal
  }
];

export function FeatureSection() {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:w-[1200px] mx-5">
      {list.map((item, index) => {
        if (!enableAnimations()) {
          return (
            <div className="cursor-pointer flex flex-col items-center justify-center shadow-2xl text-4xl px-10 py-6 bg-gradient-to-br from-rose-500/20 to-rose-800 rounded-2xl border-blue-400">
              <img
                src={item.asset}
                alt="Big Rummy"
                className="h-[60px] md:h-[120px] w-[200px] mx-auto object-contain"
              />
              <h1 className="font-custom font-bold text-2xl text-white w-full text-center mt-4 leading-none	">
                {t(item.titleKey)}
              </h1>
              <h2 className="font-custom text-sm mt-2 text-white w-full text-center opacity-70">
                {t(item.subKey)}
              </h2>
            </div>
          );
        }

        return (
          <BlurFade key={index} delay={0.25 + index * 0.05} inView>
            <MagicCard
              className="cursor-pointer flex flex-col items-center justify-center shadow-2xl text-4xl px-10 py-6 bg-gradient-to-br from-rose-500/20 to-rose-800 rounded-2xl border-blue-400"
              gradientColor={theme === "dark" ? "#262626" : "#D9D9D955"}
            >
              <img
                src={item.asset}
                alt="Big Rummy"
                className="h-[60px] md:h-[120px] w-[200px] mx-auto object-contain"
              />
              <h1 className="font-custom font-bold text-2xl text-white w-full text-center mt-4 leading-none	">
                {t(item.titleKey)}
              </h1>
              <h2 className="font-custom text-sm mt-2 text-white w-full text-center opacity-70">
                {t(item.subKey)}
              </h2>
            </MagicCard>
          </BlurFade>
        );
      })}
    </div>
  );
}
