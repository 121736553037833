import React, { useState } from "react";
import ParticlesBackground from "../components/particles";
import { HeaderSection } from "../components/header";
import { useTranslation } from "react-i18next";
import { useDownload } from "../lib/downloadUtils";
import Popup from "../components/popup-download";

export default function Download() {
  const { t } = useTranslation();
  const { onDownload } = useDownload();
  const [showPopup, setShowPopup] = useState(false);

  const handleDownloadClick = async () => {
    const result = await onDownload();
    if (result === "popup") {
      setShowPopup(true);
    }
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="min-h-screen flex items-center bg-gradient-radial flex-col relative overflow-hidden">
      <div className="min-h-screen flex items-center bg-gradient-radial flex-col relative overflow-hidden justify-center">
        <button
          className="relative inline-flex h-12 md:h-16 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-50 transform transition-all duration-300 hover:scale-110"
          onClick={handleDownloadClick}
        >
          <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#eccd89_0%,#856935_50%,#edc976_100%)]" />
          <span className="text-amber-300 inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-70 px-8 font-bold text-gray-50 backdrop-blur-3xl text-2xl">
            {t("download")}
          </span>
        </button>
      </div>
      {showPopup && <Popup onClose={handleClosePopup} />}
      <ParticlesBackground />
      <HeaderSection />
    </div>
  );
}
