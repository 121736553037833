import { useTranslation } from "react-i18next";
import { AnimatedBeamDemo } from "../components/network";
import { BorderBeam } from "./magicui/border";
import shareT from "./../assets/shareT.png";
import { enableAnimations } from "../lib/utils";

const EarnSection = () => {
  const { t } = useTranslation();

  return (
    <div className=" relative  flex flex-col items-center  bg-gradient-to-r from-emerald-900 to-emerald-950 p-2 md:p-10 rounded-3xl border border-emerald-600 m-2 mb-10 -mt-10  bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-70 gap-2 mx-5">
      {enableAnimations() && (
        <BorderBeam size={250} duration={12} delay={0} className="" />
      )}
      <h1 className="text-white text-2xl md:text-2xl font-custom text-center font-bold pt-5 md:pt-0">
        {t("earnTitle")}
      </h1>
      <h1 className="text-white text-4xl md:text-6xl font-custom text-center font-bold">
        <img src={shareT} alt="Big Rummy" className="h-[30px] md:h-[50px]" />
      </h1>
      <h2 className="text-white text-lg md:text-xl font-custom text-center opacity-50">
        {t("referralProgram")}
      </h2>
      <div className="flex flex-col md:flex-row items-center justify-between md:mt-5 w-full md:w-[1100px] p-2 md:pt-0">
        <div className="text-white text-xl md:text-2xl font-custom md:mr-5 w-full md:w-[600px] pl-12 pb-10">
          <h3 className="font-bold mb-4 text-green-500">{t("howToEarn")}</h3>
          <div className="flex items-center mb-4">
            <div className="min-w-10 h-10 md:w-12 md:h-12 bg-green-600 text-white flex items-center justify-center rounded-full font-bold mr-2">
              1
            </div>
            <p>{t("downloadApp")}</p>
          </div>
          <div className="flex items-center mb-4">
            <div className="min-w-10 h-10 md:w-12 md:h-12 bg-green-600 text-white flex items-center justify-center rounded-full font-bold mr-2">
              2
            </div>
            <p>{t("clickReferral")}</p>
          </div>
          <div className="flex items-center mb-4">
            <div className="min-w-10 h-10 md:w-12 md:h-12 bg-green-600 text-white flex items-center justify-center rounded-full font-bold mr-2">
              3
            </div>
            <p>{t("shareLink")}</p>
          </div>
        </div>
        <div className="mt-2 md:mt-0 w-full md:w-[700px]">
          {enableAnimations() && <AnimatedBeamDemo />}
        </div>
      </div>
    </div>
  );
};

export default EarnSection;
