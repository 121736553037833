import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Support } from "../assets/support.svg";

export function HeaderSection() {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <div className="fixed top-5 right-5 md:right-5 flex gap-10 z-20 justify-center items-center bg-black p-0 md:p-2 bg-opacity-80 md:bg-opacity-50 rounded-none md:rounded-2xl bg-clip-padding backdrop-filter backdrop-blur-md w-full md:w-auto md:top-5 md:right-5 top-auto right-auto left-1/2 transform -translate-x-1/2 md:left-auto md:transform-none">
      <button
        className={`font-custom text-sm md:text-lg font-bold cursor-pointer transition-all duration-300 hover:scale-110 mx-1 bg-black/10 rounded ${
          selectedLanguage === "en" ? "text-white" : "text-white/50"
        }`}
        onClick={() => changeLanguage("en")}
      >
        English
      </button>
      <button
        className={`font-custom text-sm md:text-lg font-bold cursor-pointer transition-all duration-300 hover:scale-110 mx-1 bg-black/10 rounded ${
          selectedLanguage === "ur" ? "text-white" : "text-white/50"
        }`}
        onClick={() => changeLanguage("ur")}
      >
        اُردُو
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          window.open(
            "https://tawk.to/chat/66f2aea44cbc4814f7dde97f/1i8hvkcq2",
            "_blank"
          );
        }}
        className="flex gap-2 md:gap-2 z-50 flex justify-center items-center bg-black bg-opacity-20 p-2 md:p-3 md:rounded-2xl rounded-xl cursor-pointer transition-all duration-300 hover:scale-105"
      >
        <img src="support-min.png" alt="Support" className="w-12 h-10 z-10" />
        {/* <Support width={30} height={30} className="z-10" /> */}
      </button>
    </div>
  );
}
