import { useState } from "react";
import { useTranslation } from "react-i18next";
import bigr from "./../assets/appLogo.png";
import android from "./../assets/android.svg";
import apple from "./../assets/apple.svg";
import Popup from "../components/popup-download";
import { useDownload } from "../lib/downloadUtils";

const FixedBottomBar = () => {
  const { t } = useTranslation();
  const { onDownload } = useDownload();
  const [showPopup, setShowPopup] = useState(false);

  const handleDownloadClick = async () => {
    const result = await onDownload();
    if (result === "popup") {
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className="fixed bottom-0 w-full bg-[#730013] text-white text-center py-0 md:py-0 z-20 rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-50">
        <div className="flex flex-col md:flex-row items-center justify-center md:gap-4 gap-0 my-1">
          <div className="flex items-center justify-center gap-4 my-0 md:my-1">
            <img src={bigr} alt="Big Rummy" className="h-[30px] md:h-[50px]" />
            <span className="text-lg md:text-2xl font-bold font-custom">
              {t("downloadAppNow")}
            </span>
            <img src={android} alt="Big Rummy" className="h-[20px]" />
            <img src={apple} alt="Big Rummy" className="h-[20px]" />
          </div>
          <button
            className="relative inline-flex h-12 md:h-16 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-50 transform transition-all duration-300 hover:scale-110"
            onClick={handleDownloadClick}
          >
            <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#eccd89_0%,#856935_50%,#edc976_100%)]" />
            <span className="text-amber-300 inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-70 px-8 font-bold text-gray-50 backdrop-blur-3xl text-2xl">
              {t("download")}
            </span>
          </button>
        </div>
      </div>
      {showPopup && <Popup onClose={handleClosePopup} />}
    </>
  );
};

export default FixedBottomBar;
