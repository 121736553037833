import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Adjust from "@adjustcom/adjust-web-sdk";
Adjust.initSdk({
  appToken: "3o1gcc1sw9hc",
  environment: "production",
});



// Adjust.waitForWebUUID()
//   .then(webUUID => {
//     console.log(webUUID);
//     Adjust.trackEvent({
//       eventToken: 'gbco5d',
//     });
//     if (webUUID) {
//       return fetch(`https://s2s.adjust.com/event?s2s=1&app_token=v470800ty77k&event_token=nz73h3&web_uuid=${webUUID}&environment=production`);
//     } else {
//       throw new Error('webUUID is undefined');
//     }
//   })
//   .then(response => response.json())
//   .then(data => console.log('API call successful:', data))
//   .catch(error => console.error('API call error:', error));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
