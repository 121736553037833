import { type ClassValue, clsx } from "clsx";
import { isMobile } from "react-device-detect";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function copyToClipboard(text: string | null) {
  if (!text) return;

  function fallbackCopy(text: string) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.style.position = "fixed";
    textArea.style.opacity = "0";
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      console.log("Text copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }

    document.body.removeChild(textArea);
  }

  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(
      () => console.log("Text copied to clipboard"),
      (err) => fallbackCopy(text)
    );
  } else {
    fallbackCopy(text);
  }
}

export const enableAnimations = () => {
  return !isMobile;
};
